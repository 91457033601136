import { ApolloClient, ApolloLink, HttpLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { relayStylePagination } from '@apollo/client/utilities';
import { createConsumer } from '@rails/actioncable';

import { ActionCableLink } from './ActionCableLink';
import possibleTypes from '../../app/graphql/possibleTypes.json';
import connectionTypes from '../../app/graphql/connectionTypes.json';
import { environment } from '~/constantDefinitions';

const edgesFieldPolicy = {
  read: (existingEdges, { canRead }) => existingEdges.filter((edge) => canRead(edge.node)),
};

const defaultConnectionPolicy = { fields: { edges: edgesFieldPolicy } };

const defaultConnectionTypePolicies = connectionTypes.data.reduce(
  (policies, type) => ({ ...policies, [type]: defaultConnectionPolicy }),
  {},
);

// returns relayStylePagination helper methods when the
// field alias matches the given alias
function relayStylePaginationForFieldAlias(alias) {
  return {
    keyArgs(args, context) {
      if (context?.field?.alias?.value === alias) {
        return false;
      }

      return Object.keys(args || {});
    },

    read(existing, options) {
      if (options?.field?.alias?.value === alias) {
        return relayStylePagination().read(existing, options);
      }

      return existing;
    },

    merge(existing, incoming, options) {
      if (options?.field?.alias?.value === alias) {
        return relayStylePagination().merge(existing, incoming, options);
      }

      return incoming;
    },
  };
}

export const makeCache = () =>
  new InMemoryCache({
    possibleTypes,
    addTypename: true,
    dataIdFromObject: (obj) => obj.id,
    typePolicies: {
      ...defaultConnectionTypePolicies,
      Account: {
        fields: {
          projectSearch: {
            keyFields: ['filters', 'search'],
            merge(_existing, incoming = []) {
              return incoming;
            },
          },

          projectSearchByConnection: relayStylePaginationForFieldAlias('infiniteProjects'),

          allContentItems: relayStylePaginationForFieldAlias('infiniteItems'),

          archivedItems: relayStylePagination(),

          medias: relayStylePagination(),

          projects: relayStylePagination([
            'filters',
            'orderBy',
            'after',
            'before',
            'first',
            'last',
          ]),

          videos: relayStylePaginationForFieldAlias('infiniteVideos'),

          channels: relayStylePaginationForFieldAlias('infiniteChannels'),

          tags: relayStylePagination(),
        },
      },
      Media: {
        fields: {
          topLevelTeamComments: relayStylePagination(),
          topLevelAudienceComments: relayStylePagination(),
        },
      },
      CurrentStatus: {
        merge: (_, incoming) => incoming,
      },
      AuthorizationResult: {
        merge: (_, incoming) => incoming,
      },
      AuthorizationReason: {
        merge: (_, incoming) => incoming,
      },
      AnonymousMediaGroup: {
        fields: {
          medias: relayStylePagination(),
        },
      },
      AnonymousProject: {
        fields: {
          mediaGroups: relayStylePagination(),
        },
      },
      AnonymousMedia: {
        fields: {
          topLevelTeamComments: relayStylePagination(),
          topLevelAudienceComments: relayStylePagination(),
        },
      },
      ChannelSection: {
        fields: {
          episodes: relayStylePagination(),
        },
      },
      Channel: {
        fields: {
          channelRoles: relayStylePagination(),
          medias: relayStylePagination(),
          sections: relayStylePagination(),
        },
      },
      MediaGroup: {
        fields: {
          medias: relayStylePagination(),
        },
      },
      Project: {
        fields: {
          mediaGroups: relayStylePagination(['untitled']),
          medias: relayStylePagination(),
          permissionsForCurrentContact: {
            merge(_existing, incoming = null) {
              return { ..._existing, ...incoming };
            },
          },
        },
      },
      PodcastImportRequest: {
        fields: {
          podcastFileImportsWhereFailedOrMediaFailed: relayStylePagination(),
          parsedChannel: {
            merge(_existing, incoming = null) {
              return incoming;
            },
          },
        },
      },
      Contact: {
        fields: {
          containersWithAdminPermissions: relayStylePagination(),
          channelsWithAdminPermissions: relayStylePagination(),
          projectsWithAdminPermissions: relayStylePagination(),
          accountsForSelection: relayStylePagination([
            'includeAgencySubAccounts',
            'after',
            'before',
            'first',
            'last',
          ]),
          channelRoles: relayStylePagination(),
          permissions: {
            merge(existing = {}, incoming = {}) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      Poll: {
        fields: {
          questions: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Question: {
        fields: {
          options: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      LiveStreamEvent: {
        fields: {
          settings: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          customizations: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Form: {
        fields: {
          customizations: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      ReviewLinkMedia: {
        fields: {
          topLevelTeamComments: relayStylePagination(),
        },
      },
    },
  });

const getLinks = (callbacks, schema, opts) => {
  const headers = { 'X-CSRF-TOKEN': window._auth_token };

  if (schema) {
    headers['X-WISTIA-GQL-SCHEMA'] = schema;
  }

  /*
   * The expiringAccessToken stored in the sessionStorage can be changed
   * asynchronously and without redrawing the ApolloProvider which is problematic. For this
   * reason we need a custom fetch which can check these values on every graphql query. Doing
   * this the normal way would store stale values.
   */
  const tokenAwareFetch = (uri, passedOptions) => {
    const token = opts.tokenFetcher ? opts.tokenFetcher() : undefined;
    const options = { ...passedOptions };

    options.credentials = opts.tokenFetcher ? 'omit' : 'include';
    if (opts.tokenFetcher) {
      options.headers.Authorization = `Bearer ${token}`;
    }

    return fetch(uri, options);
  };

  const httpLink = new HttpLink({
    uri: ({ operationName }) => `/graphql?op=${operationName}`,
    fetch: tokenAwareFetch,
    headers,
  });

  const hasSubscriptionOperation = ({ query: { definitions } }) =>
    definitions.some(
      ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
    );

  const cable = createConsumer();

  const link = ApolloLink.split(hasSubscriptionOperation, new ActionCableLink({ cable }), httpLink);

  const successLink = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      if (!response.errors) {
        if (callbacks.onSuccess) {
          callbacks.onSuccess();
        }
      }

      return response;
    }),
  );

  const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations = [], path }) => {
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${message}, line: ${locations[0]?.line}, Column: ${locations[0]?.column}, Path: ${path}`,
        );
      });
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}`);

      if (networkError.statusCode === 403 || networkError.statusCode === 401) {
        let redirectURL;

        if (
          typeof networkError.result === 'object' &&
          typeof networkError.result.redirect_to === 'string'
        ) {
          const redirectURLString = networkError.result.redirect_to;
          redirectURL = new URL(redirectURLString, window.location.origin);
        } else {
          const redirectTo = encodeURIComponent(window.location.toString());
          redirectURL = new URL(
            `/redirect_to_login?redirect_to=${redirectTo}`,
            window.location.origin,
          );
        }
        if (!window.apolloRedirectingDueToLogout) {
          window.apolloRedirectingDueToLogout = true;
          window.location.assign(redirectURL);
          return forward(operation);
        }
      }

      if (callbacks.onNetworkError) {
        callbacks.onNetworkError(networkError);
      }
    }

    return null;
  });

  // Only retries for 401's
  // buys some time to avoid an error page, as window.setlocation is async
  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (_error, operation) => operation.getContext().response?.status === 401,
    },
  });

  return from([successLink, retryLink, errorLink, link]);
};

/**
 * @param {Object} opts Options to pass to the apollo client
 * @param {Object} callbacks Callbacks to pass to ApolloLink
 * @param {'AnonymousSchema'|'WistiaSchema'|undefined} schema the schema to access
 * @returns ApolloClient
 */
export const getApolloClient = (opts = {}, callbacks = {}, schema = undefined) => {
  let headers = null;
  if (schema) {
    headers = {
      'X-WISTIA-GQL-SCHEMA': schema,
    };
  }

  return new ApolloClient({
    ...opts,
    link: getLinks(callbacks, schema, opts),
    connectToDevTools: environment === 'development',
    cache: makeCache(),
    headers,
  });
};
